import React, { Component, Suspense, lazy } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import Grid from '@kurtosys/ksys-app-components/dist/components/base/Grid';

@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}
	render() {
		const { className, appStore, selector, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}
		const { theme, show, showPlaceholder, noDataPlaceholder: noDataPlaceholderText, noDataPlaceholderDisclaimer, libraryComponentsConfiguration, appCardProps, gridProps, getTranslateFunction, culture, assets, assetCacheOptions, assetRegisters, getAccessibilityTextFunction } = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer { ...noDataPlaceholderDisclaimer } />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{ noDataPlaceholderText }</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();

		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } culture={ culture } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters } accessibilityText={ accessibilityText } dataLayerHelper={ appStore.dataLayerHelper }>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					<Card { ...appCardProps }>
						{ showPlaceholder && noDataPlaceholder }
						{ !showPlaceholder && <Grid queryStore={ queryStore } { ...gridProps } /> }
					</Card>
				</Wrapper>
			</Application>
		);
	}
}

export default InjectedStyledComponent(App, 'app');
