import React, { Component } from 'react';
import { IAllFundsActionProps, IAllFundsActionState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';

@inject('appStore', 'allFundsActionStore')
@observer
export class AllFundsAction extends Component<IAllFundsActionProps, IAllFundsActionState> {
	static configurationKey: 'allFundsAction' = 'allFundsAction';
	static styleKey: 'allFundsAction' = 'allFundsAction';
	constructor(props: IAllFundsActionProps) {
		super(props);
	}
	render() {
		const { className, allFundsActionStore } = this.props;

		if (!allFundsActionStore) {
			return null;
		}

		const { show, props } = allFundsActionStore;

		if (!show) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<Button { ...props } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(AllFundsAction, 'allFundsAction');
