import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFilterConfiguration } from '../models';

export class FilterStore extends StoreBase {
	static componentKey: 'filter' = 'filter';

	@computed
	get configuration(): IFilterConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FilterStore.componentKey);
		}
	}

	@action
	async initialize() : Promise<void> {

	}
}