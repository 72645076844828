import React, { Component } from 'react';
import {
	IFiltersClearActionProps,
	IFiltersClearActionState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';

@inject('appStore', 'filtersClearActionStore')
@observer
export class FiltersClearAction extends Component<IFiltersClearActionProps, IFiltersClearActionState> {
	static configurationKey: 'filtersClearAction' = 'filtersClearAction';
	static styleKey: 'filtersClearAction' = 'filtersClearAction';
	constructor(props: IFiltersClearActionProps) {
		super(props);
	}
	render() {
		const { className, filtersClearActionStore } = this.props;

		if (!filtersClearActionStore) {
			return null;
		}

		const { show, props } = filtersClearActionStore;

		if (!show) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<Button { ...props } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FiltersClearAction, 'filtersClearAction');

