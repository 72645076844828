import React, { Component } from 'react';
import { IFilterProps, IFilterState } from './models';
import { inject, observer } from 'mobx-react';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import FilterTitle from './styledComponents/FilterTitle';
import FilterButtons from '../FilterButtons';
import FilterDropdown from '../FilterDropdown';

@inject('appStore', 'filterStore')
@observer
export class Filter extends Component<IFilterProps, IFilterState> {
	static configurationKey: 'filter' = 'filter';
	static styleKey: 'filter' = 'filter';
	constructor(props: IFilterProps) {
		super(props);
	}
	render() {
		const { className, filterStore, id, type, mode, title, values, preSelected, placeholder, onFilterOptionSelect } = this.props;

		if (!filterStore) {
			return null;
		}

		const props = {
			id,
			mode,
			values,
			preSelected,
			placeholder,
			onFilterSelect: onFilterOptionSelect,
		};

		let SubFilter;
		if (type === 'button') {
			SubFilter = FilterButtons;
		}
		else {
			SubFilter = FilterDropdown;
		}

		return (
			<Wrapper className={ className }>
				{ title && <FilterTitle><Text value={ title } /></FilterTitle> }
				<SubFilter { ...props } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Filter, 'filter');
