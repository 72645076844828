import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	components: {
		app: {
			cardProps: {
				props: {
					title: 'Search our funds',
				},
			},
			gridProps: {
				breakpoints: [
					{
						size: 'medium',
						modifier: 'up',
						label: 'Medium Up',
						props: {
							grid: {
								rows: [
									{
										columns: [
											{
												components: [
													{
														key: 'search',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
											{
												components: [
													{
														key: 'searchAction',
													},
												],
												style: {
													flex: {
														grow: '0',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filtersTitle',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
											{
												components: [
													{
														key: 'filtersClearAction',
													},
												],
												style: {
													flex: {
														grow: '0',
													},
												},
											},
											{
												components: [
													{
														key: 'base.text',
														props: {
															value: '|',
														},
													},
												],
												style: {
													flex: {
														grow: '0',
													},
													lineHeight: '50px',
												},
											},
											{
												components: [
													{
														key: 'allFundsAction',
													},
												],
												style: {
													flex: {
														grow: '0',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filters',
													},
												],
											},
											{
												components: [
													{
														key: 'filtersAction',
													},
												],
											},
										],
									},
								],
							},
						},
					},
					{
						size: 'small',
						modifier: 'down',
						label: 'Small Down',
						props: {
							grid: {
								rows: [
									{
										columns: [
											{
												components: [
													{
														key: 'search',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'searchAction',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filtersTitle',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filters',
													},
												],
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filtersClearAction',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'filtersAction',
													},
												],
											},
										],
									},
									{
										columns: [
											{
												components: [
													{
														key: 'allFundsAction',
													},
												],
												style: {
													flex: {
														grow: '1',
													},
												},
											},
										],
									},
								],
							},
						},
					},
				],
			},
		},
		allFundsAction: {
			props: {
				text: 'View All Funds',
			},
		},
		search: {
			props: {
				iconPosition: 'left',
				placeholder: 'Search by Fund Name or ISIN',
				pillProps: {
					pillPosition: 'right',
					priority: 'primary',
					value: '',
				},
				minCharacterTrigger: 3,
			},
			result: {
				responseCount: {
					label: '{count} share classes ',
					placeholder: 'count',
				},
				searchRequest: {
					searchEntitiesId: 'classSearch',
					initialSearchCountEntitiesId: 'initSearchClassCount',
					observedFilters: ['fundCode', 'classCode', 'investmentTeam'],
				},
				searchParentRequest: {
					searchEntitiesId: 'fundSearch',
					clientCodeProperty: 'fund_code',
				},
			},
		},
		searchResultItem: {
			parentLabel: {
				left: [
					{
						queryOptionsType: 'property',
						entityType: 'FUND',
						code: 'fund_name',
					},
				],
			},
			label: {
				left: [
					{
						queryOptionsType: 'none',
						value: '{sub:fund} - {sub:class}',
						subQueries: [
							{
								queryId: 'fund',
								code: 'fund_name',
								entityType: 'CLSS',
								queryOptionsType: 'property',
							},
							{
								queryId: 'class',
								code: 'short_name',
								entityType: 'CLSS',
								queryOptionsType: 'property',
							},
						],
					},
					{
						queryOptionsType: 'none',
						value: 'ISIN: {sub:isin}',
						subQueries: [
							{
								queryId: 'isin',
								code: 'isin',
								entityType: 'CLSS',
								queryOptionsType: 'property',
							},
						],
					},
				],
			},
		},
		searchAction: {
			props: {
				text: 'Search Funds',
			},
		},
		filters: {
			expandable: true,
			initiallyExpanded: true,
			fundLists: ['active_classes'],
			fundListExclusions: ['{app:fundList}'],
			options: [
				{
					id: 'fundCode',
					entityType: 'CLSS',
					property: 'fund_code',
					placeholder: 'Fund Code',
					valueAliases: {
						'BOND': '007 BOND',
					},
					allowedValues: [
						'ALPHA',
						'BOND',
					],
				},
				{
					id: 'classCode',
					entityType: 'CLSS',
					property: 'class_code',
					type: 'dropdown',
					mode: 'multi',
					placeholder: 'Class Code',
				},
				{
					id: 'investmentTeam',
					entityType: 'CLSS',
					property: 'investment_team',
					title: 'Investment Team',
					type: 'button',
					mode: 'single',
					placeholder: 'Investment Team',
					valueAliases: {
						Macro: 'Macro Team',
						'Fixed Income': 'Fixed Income Team',
					},
					customSort: [
						'Hedge',
					],
					sortOptions: {
						direction: 'DESC',
					},
				},
			],
		},
		filtersAction: {
			props: {
				text: 'Filter Funds',
			},
		},
		filtersClearAction: {
			props: {
				text: 'Clear Filters ({count})',
			},
		},
		filtersTitle: {
			props: {
				title: 'Filters',
				level: 3,
			},
		},
		redirects: {
			baseInputs: [
				{
					key: 'culture',
					query: {
						queryOptionsType: 'input',
						key: 'culture',
					},
				},
			],
			options: [
				{
					key: 'fund-explorer',
					inputs: [
						{
							key: 'term',
							query: {
								code: 'fund_name',
								entityType: 'FUND',
								queryOptionsType: 'property',
							},
						},
					],
					redirect: {
						defaultUrl: '/{baseInput:culture}/fund-explorer',
						base: 'http://localhost:15020/',
						options: {
							queryStringParams: [
								{ key: 'term', value: '{search:term}', optional: true },
								{ key: 'term', value: '{input:term}', optional: true },
								{ key: 'fundCode', value: '{filter:fundCode}', optional: true },
								{ key: 'classCode', value: '{filter:classCode}', optional: true },
								{ key: 'investmentTeam', value: '{filter:investmentTeam}', optional: true },
							],
							preserveOrigin: false,
							preserveOriginPath: false,
							preserveOriginHash: false,
							preserveOriginQueryString: false,
							target: '_self',
						},
					},
				},
				{
					key: 'fund-page',
					inputs: [
						{
							key: 'fundName',
							query: {
								code: 'fund_name',
								entityType: 'CLSS',
								queryOptionsType: 'property',
								transforms: [
									{
										caseType: 'param',
										transformOptionsType: 'case',
									},
								],
							},
						},
						{
							key: 'className',
							query: {
								code: 'short_name',
								entityType: 'CLSS',
								queryOptionsType: 'property',
								transforms: [
									{
										caseType: 'param',
										transformOptionsType: 'case',
									},
								],
							},
						},
					],
					redirect: {
						defaultUrl: '/{baseInput:culture}/fund-page',
						base: 'http://localhost:15020/',
						options: {
							urlEndpoint: ['{input:fundName}', '{input:className}'],
							preserveOrigin: false,
							preserveOriginPath: false,
							preserveOriginHash: false,
							preserveOriginQueryString: false,
							target: '_self',
						},
					},
				},
			],
			searchAction: {
				redirectKey: 'fund-explorer',
				ignoreInputs: true,
			},
			searchResultChildItem: {
				redirectKey: 'fund-page',
				ignoreSearchTerm: true,
			},
			searchResultParentItem: {
				redirectKey: 'fund-explorer',
				ignoreSearchTerm: true,
			},
			filterAction: {
				redirectKey: 'fund-explorer',
				ignoreInputs: true,
			},
			allFundsAction: {
				redirectKey: 'fund-explorer',
				ignoreInputs: true,
				ignoreFilterInputs: true,
				ignoreSearchTerm: true,
			},
		},
	},
};
