import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { IGridProps } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models';
import { IEntity, IQueryKeyValue, IKeyValueString } from '../../../models/commonTypes';
import { IConfigurationRedirect } from '../../../models/app/IConfigurationRedirect';
import { Manifest } from '../../../configuration/Manifest';
import { helpers, utils, query } from '@kurtosys/ksys-app-template';
import { IConfigurationRedirectTarget } from '../../../models/app/IConfigurationRedirectTarget';
import { TRedirectAction } from '../../../models/app/TRedirectAction';
import { IRedirectOptions } from '../../../models/app/IRedirectOptions';
import { TRedirectInput } from '../../../models/app/TRedirectInput';
import { IRedirectActionProps } from '../../../models/app/IRedirectActionProps';

/* [Component: appStoreComponentImport] */

const Query = query.Query;

export class AppStore extends AppStoreBase {
	url: URL;
	rawUrl: string;
	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
		this.rawUrl = url;
		this.url = new URL(url);
	}

	@computed
	get hasData(): boolean {
		return [
			this.storeContext.searchStore.hasData,
			this.storeContext.searchActionStore.hasData,
			this.storeContext.filtersTitleStore.hasData,
			this.storeContext.filtersStore.hasData,
			this.storeContext.filtersClearActionStore.hasData,
			this.storeContext.filtersActionStore.hasData,
			this.storeContext.allFundsActionStore.hasData,
		].some(bool => bool);
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get gridProps(): IGridProps | undefined {
		const gridProps = this.appComponentConfiguration && this.appComponentConfiguration.gridProps;
		return gridProps;
	}

	@computed
	get redirectConfiguration(): IConfigurationRedirect | undefined {
		if (this.configuration) {
			const { components } = this.configuration;
			if (components) {
				const { redirects: redirect } = components;
				if (redirect) {
					return redirect;
				}
			}
		}
		return;
	}

	getRedirectActionProps(redirectAction: TRedirectAction): IRedirectActionProps | undefined {
		if (this.redirectConfiguration) {
			const { options } = this.redirectConfiguration;
			return this.redirectConfiguration[redirectAction];
		}
		return;
	}

	getRedirectOption(redirectAction: TRedirectAction): IConfigurationRedirectTarget | undefined {
		if (this.redirectConfiguration) {
			const { options } = this.redirectConfiguration;
			if (options.length > 0) {
				const redirectActionProps = this.getRedirectActionProps(redirectAction);
				if (redirectActionProps) {
					return options.find(option => option.key === redirectActionProps.redirectKey);
				}
			}
		}
		return;
	}

	executeRedirectAction(redirectAction: TRedirectAction, options?: IRedirectOptions) {
		const redirectActionProps = this.getRedirectActionProps(redirectAction);
		if (redirectActionProps) {
			const defaultInputPriority: TRedirectInput[] = [
				'baseRedirectQueryInputs',
				'redirectQueryInputs',
				'filterInputs',
				'searchTermInput',
			];
			const {
				ignoreBaseInputs,
				ignoreFilterInputs,
				ignoreInputs,
				ignoreSearchTerm,
				inputPriority = defaultInputPriority,
			} = redirectActionProps;

			const redirectConfiguration = this.getRedirectOption(redirectAction);
			if (redirectConfiguration) {

				let finalInputs = {};
				inputPriority.forEach((inputType: TRedirectInput) => {
					switch (inputType) {
						case 'baseRedirectQueryInputs':
							if (!ignoreBaseInputs) {
								finalInputs = { ...finalInputs, ...this.baseRedirectInputs };
							}
							break;
						case 'redirectQueryInputs':
							if (!ignoreInputs) {
								const entity = options && options.entity;
								finalInputs = { ...finalInputs, ...this.getRedirectInputs(redirectAction, entity) };
							}
							break;
						case 'filterInputs':
							if (!ignoreFilterInputs) {
								finalInputs = { ...finalInputs, ...this.getFilterInputs };
							}
							break;
						case 'searchTermInput':
							if (!ignoreSearchTerm) {
								const { searchStore } = this.storeContext;
								const { term } = searchStore;
								finalInputs = { ...finalInputs, ...{ ['search:term']: term } };
							}
							break;
						default:
							break;
					}
				});

				const { redirect } = redirectConfiguration;
				const redirectHelper = new helpers.RedirectHelper(this.rawUrl);
				redirectHelper.go(redirect, finalInputs);
			}
		}
	}

	getRedirectInputs(redirectAction: TRedirectAction, entity?: IEntity): object {
		let redirectInputs = {};
		const redirectConfiguration = this.getRedirectOption(redirectAction);
		if (redirectConfiguration) {
			const { inputs } = redirectConfiguration;
			if (inputs) {
				const inputQueriesByKey = inputs.reduce((redirectConfigInputs, inputConfig) => {
					const inputKey = `input:${ inputConfig.key }`;
					if (!redirectConfigInputs[inputKey]) {
						redirectConfigInputs[inputKey] = inputConfig.query;
					}
					return redirectConfigInputs;
				}, {} as IQueryKeyValue);

				const { queryStore } = this.storeContext;
				const { context, ...existingExecutionOptions } = queryStore.executionOptions;
				let entityContext;
				if (entity) {
					entityContext = {
						context: {
							entityByType: {
								[entity.type]: entity,
							},
						},
					};
				}
				const executionOptions = utils.object.deepMergeObjects(existingExecutionOptions, entityContext);
				redirectInputs = Query.convertQueryKeyValue(inputQueriesByKey || {}, executionOptions);
			}
		}
		return redirectInputs;
	}

	@computed
	get baseRedirectInputs(): object {
		if (this.redirectConfiguration) {
			const { queryStore } = this.storeContext;
			const { baseInputs } = this.redirectConfiguration;
			if (queryStore && baseInputs && baseInputs.length > 0) {
				const inputQueriesByKey = baseInputs.reduce((redirectConfigInputs, inputConfig) => {
					const baseInputKey = `baseInput:${ inputConfig.key }`;
					if (!redirectConfigInputs[baseInputKey]) {
						redirectConfigInputs[baseInputKey] = inputConfig.query;
					}
					return redirectConfigInputs;
				}, {} as IQueryKeyValue);

				return Query.convertQueryKeyValue(inputQueriesByKey || {}, queryStore.executionOptions);
			}
		}
		return {};
	}

	@computed
	get getFilterInputs(): IKeyValueString {
		const inputs: IKeyValueString = {};
		const { filtersStore } = this.storeContext;
		const { selectedFilterValues, filterOptions } = filtersStore;
		const filterIds = Object.keys(selectedFilterValues);
		filterIds.forEach((id) => {
			inputs[`filter:${ id }`] = selectedFilterValues[id].map(prop => prop.value).join(filtersStore.selectionJoin);
		});
		return inputs;
	}

	async customInitializeAfter() {
		const { searchStore, filtersStore } = this.storeContext;
		// These should be async and the app component should not wait for them to load
		searchStore.initialize();
		filtersStore.initialize();
	}

	overrideEntityContext(entity: IEntity) {
		return { context: { entityByType: { [entity.type]: entity } } };
	}

}