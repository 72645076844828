import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IAllFundsActionConfiguration, IAllFundsActionProps } from '../models';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';

export class AllFundsActionStore extends StoreBase {
	static componentKey: 'allFundsAction' = 'allFundsAction';

	@computed
	get configuration(): IAllFundsActionConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(AllFundsActionStore.componentKey);
		}
	}

	@computed
	get hasData(): boolean {
		return !!this.props;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@computed
	get props(): IButtonProps | undefined {
		if (this.configuration) {
			return {
				...this.mergeQueriesAndProps(this.configuration),
				onClick: this.onClick,
			};
		}
		return;
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	onClick = () => {
		const { appStore } = this.storeContext;
		if (appStore) {
			appStore.executeRedirectAction('allFundsAction');
		}
	}
}