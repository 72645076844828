import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFiltersTitleConfiguration } from '../models';
import { IHeadingProps } from '@kurtosys/ksys-app-components/dist/components/base/Heading/models';

export class FiltersTitleStore extends StoreBase {
	static componentKey: 'filtersTitle' = 'filtersTitle';

	@computed
	get configuration(): IFiltersTitleConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FiltersTitleStore.componentKey);
		}
	}

	@computed
	get hasData(): boolean {
		return !!this.props;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@computed
	get props(): IHeadingProps | undefined {
		return this.mergeQueriesAndProps(this.configuration);
	}

	@computed
	get isExpandable(): boolean {
		return this.storeContext.filtersStore.isExpandable;
	}

	@computed
	get isExpanded(): boolean {
		return this.storeContext.filtersStore.isExpanded;
	}

	@action
	onExpand = () => {
		this.storeContext.filtersStore.toggleExpand();
	}

	@action
	async initialize(): Promise<void> {

	}
}