import React, { Component } from 'react';
import { IFiltersTitleProps, IFiltersTitleState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import IconWrapper from './styledComponents/IconWrapper';
import TextWrapper from './styledComponents/TextWrapper';
import Wrapper from './styledComponents/Wrapper';
import Heading from '@kurtosys/ksys-app-components/dist/components/base/Heading';
import Icon from '@kurtosys/ksys-app-components/dist/components/base/Icon';

@inject('appStore', 'filtersTitleStore')
@observer
export class FiltersTitle extends Component<IFiltersTitleProps, IFiltersTitleState> {
	static configurationKey: 'filtersTitle' = 'filtersTitle';
	static styleKey: 'filtersTitle' = 'filtersTitle';
	constructor(props: IFiltersTitleProps) {
		super(props);
	}
	render() {
		const { className, filtersTitleStore } = this.props;

		if (!filtersTitleStore) {
			return null;
		}

		const { show, props, isExpandable, isExpanded, onExpand } = filtersTitleStore;

		if (!show) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<TextWrapper>
					<Heading { ...props } />
				</TextWrapper>
				{ isExpandable && (
					<IconWrapper>
						<Icon asset={ isExpanded ? 'base.dropdown.caretUp' : 'base.dropdown.caretDown' } onClick={ onExpand } />
					</IconWrapper>
				) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FiltersTitle, 'filtersTitle');
