import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFiltersClearActionConfiguration } from '../models';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';
import { utils } from '@kurtosys/ksys-app-template/dist';

export class FiltersClearActionStore extends StoreBase {
	static componentKey: 'filtersClearAction' = 'filtersClearAction';

	@computed
	get configuration(): IFiltersClearActionConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FiltersClearActionStore.componentKey);
		}
	}

	@computed
	get hasData(): boolean {
		return !!this.props;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@computed
	get props(): IButtonProps | undefined {
		const { filtersStore } = this.storeContext;

		if (this.configuration) {
			const props: IButtonProps = {
				...this.mergeQueriesAndProps(this.configuration),
				onClick: this.onClick,
			};

			props.text = utils.replacePlaceholders({ count: filtersStore.selectedFilterValuesCount }, props.text || 'Clear ({count})');

			return props;
		}

		return;
	}

	@computed
	get isExpanded(): boolean {
		return this.storeContext.filtersStore.isExpanded;
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	onClick = () => {
		const { filtersStore } = this.storeContext;
		if (filtersStore) {
			filtersStore.clearFilters();
		}
	}
}