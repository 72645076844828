import React, { Component } from 'react';
import { ISearchActionProps, ISearchActionState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';

@inject('appStore', 'searchActionStore')
@observer
export class SearchAction extends Component<ISearchActionProps, ISearchActionState> {
	static configurationKey: 'searchAction' = 'searchAction';
	static styleKey: 'searchAction' = 'searchAction';
	constructor(props: ISearchActionProps) {
		super(props);
	}
	render() {
		const { className, searchActionStore } = this.props;

		if (!searchActionStore) {
			return null;
		}

		const { buttonProps, show } = searchActionStore;

		if (!show) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				<Button { ...buttonProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SearchAction, 'searchAction');
