import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISearchResultItemConfiguration } from '../models';

export class SearchResultItemStore extends StoreBase {
	static componentKey: 'searchResultItem' = 'searchResultItem';

	@computed
	get configuration(): ISearchResultItemConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SearchResultItemStore.componentKey);
		}
	}

	@action
	async initialize() : Promise<void> {

	}
}