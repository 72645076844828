export function loadScript(src: string, callback: () => void): HTMLScriptElement | undefined {
	const scriptStoreKey = 'ksys-loadScript-store';
	if (window && !(window as any)[scriptStoreKey]) {
		(window as any)[scriptStoreKey] = {};
	}
	const scriptsLoaded = (window && (window as any)[scriptStoreKey] || {});

	const scripts = document.scripts;
	for (let scriptIndex = 0; scriptIndex < scripts.length; scriptIndex++) {
		const documentScript = scripts[scriptIndex];
		if (documentScript.dataset.source !== 'ksys') {
			scriptsLoaded[documentScript.src] = true;
		}
	}

	let scriptElement: HTMLScriptElement | undefined;

	for (let scriptIndex = 0; scriptIndex < scripts.length; scriptIndex++) {
		const script = scripts[scriptIndex];
		if (script.src === src) {
			scriptElement = script;
			break;
		}
	}
	if (scriptElement && scriptsLoaded[src]) {
		if (callback) {
			callback();
		}
		return scriptElement;
	}

	let mustInsert = false;
	if (!scriptElement) {
		mustInsert = true;
		scriptElement = document.createElement('script');
		scriptElement.type = 'text/javascript';
		scriptElement.src = src;
		scriptElement.crossOrigin = 'crossorigin';
		scriptElement.dataset.source = 'ksys';
	}
	if (scriptElement.addEventListener) {
		scriptElement.addEventListener('load', () => {
			scriptsLoaded[src] = true;
			if (callback) {
				callback();
			}
		}, false);
		scriptElement.addEventListener('error', (error) => {
			delete scriptsLoaded[src];
		}, false);
	}
	if (mustInsert) {
		const head = document.querySelector('head');
		if (head && head) {
			head.appendChild(scriptElement);
		}
	}
}

export function loadIEPolyfill(callback: () => void) {
	const isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
	if (isIE11) {
		const src = 'https://api-uk.kurtosys.app/applicationManager/apps/ksys-app-assets/ksys-ie-polyfill.1.0.0.production.min.js?cache={"maxAge":604800}';
		loadScript(src, callback);
	}
	else {
		callback();
	}
}

loadIEPolyfill(() => {
	require('./initialize');
});
