import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISearchActionConfiguration } from '../models';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';

export class SearchActionStore extends StoreBase {
	static componentKey: 'searchAction' = 'searchAction';

	@computed
	get configuration(): ISearchActionConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SearchActionStore.componentKey);
		}
	}

	@computed
	get componentProps(): IButtonProps | undefined {
		return this.mergeQueriesAndProps(this.configuration);
	}

	@computed
	get hasData(): boolean {
		return !!this.componentProps;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@computed
	get buttonProps(): IButtonProps | undefined {
		if (this.componentProps) {
			return { ...this.componentProps, ...{ onClick: this.onClick } };
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	onClick = () => {
		const { appStore } = this.storeContext;
		appStore.executeRedirectAction('searchAction');
	}
}