import { IComponentConfiguration } from '@kurtosys/ksys-app-components/dist/models/IComponentConfiguration';
import Button from '@kurtosys/ksys-app-components/dist/components/base/Button';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import Text from '@kurtosys/ksys-app-components/dist/components/base/Text';
import { Search } from '../components/Search/Search';
import { SearchAction } from '../components/SearchAction/SearchAction';
import { Filters } from '../components/Filters/Filters';
import { FiltersTitle } from '../components/FiltersTitle/FiltersTitle';
import { FiltersAction } from '../components/FiltersAction/FiltersAction';
import { FiltersClearAction } from '../components/FiltersClearAction/FiltersClearAction';
import { AllFundsAction } from '../components/AllFundsAction/AllFundsAction';

export const LIBRARY_COMPONENTS_CONFIGURATION: IComponentConfiguration = {
	base: {
		grid: {
			components: {
				'base.button': {
					key: 'base.button',
					component: Button,
				},
				'base.card': {
					key: 'base.card',
					component: Card,
				},
				'base.text': {
					key: 'base.text',
					component: Text,
				},
				'allFundsAction': {
					key: 'allFundsAction',
					component: AllFundsAction,
				},
				'search': {
					key: 'search',
					component: Search,
				},
				'searchAction': {
					key: 'searchAction',
					component: SearchAction,
				},
				'filters': {
					key: 'filters',
					component: Filters,
				},
				'filtersAction': {
					key: 'filtersAction',
					component: FiltersAction,
				},
				'filtersClearAction': {
					key: 'filtersClearAction',
					component: FiltersClearAction,
				},
				'filtersTitle': {
					key: 'filtersTitle',
					component: FiltersTitle,
				},
			},
		},
	},
};