import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { SearchStore } from '../components/Search/stores/SearchStore';
import { SearchResultItemStore } from '../components/SearchResultItem/stores/SearchResultItemStore';
import { SearchActionStore } from '../components/SearchAction/stores/SearchActionStore';
import { FiltersStore } from '../components/Filters/stores/FiltersStore';
import { FilterStore } from '../components/Filter/stores/FilterStore';
import { FilterButtonsStore } from '../components/FilterButtons/stores/FilterButtonsStore';
import { FilterDropdownStore } from '../components/FilterDropdown/stores/FilterDropdownStore';
import { FiltersActionStore } from '../components/FiltersAction/stores/FiltersActionStore';
import { FiltersTitleStore } from '../components/FiltersTitle/stores/FiltersTitleStore';
import { AllFundsActionStore } from '../components/AllFundsAction/stores/AllFundsActionStore';
import { FiltersClearActionStore } from '../components/FiltersClearAction/stores/FiltersClearActionStore';
import { AccessibilityStore } from '../components/App/stores/AccessibilityStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	searchStore: SearchStore;
	searchResultItemStore: SearchResultItemStore;
	searchActionStore: SearchActionStore;
	filtersStore: FiltersStore;
	filterStore: FilterStore;
	filterButtonsStore: FilterButtonsStore;
	filterDropdownStore: FilterDropdownStore;
	filtersActionStore: FiltersActionStore;
	filtersTitleStore: FiltersTitleStore;
	allFundsActionStore: AllFundsActionStore;
	filtersClearActionStore: FiltersClearActionStore;
	accessibilityStore: AccessibilityStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.searchStore = new SearchStore(this);
		this.searchResultItemStore = new SearchResultItemStore(this);
		this.searchActionStore = new SearchActionStore(this);
		this.filtersStore = new FiltersStore(this);
		this.filterStore = new FilterStore(this);
		this.filterButtonsStore = new FilterButtonsStore(this);
		this.filterDropdownStore = new FilterDropdownStore(this);
		this.filtersActionStore = new FiltersActionStore(this);
		this.filtersTitleStore = new FiltersTitleStore(this);
		this.allFundsActionStore = new AllFundsActionStore(this);
		this.filtersClearActionStore = new FiltersClearActionStore(this);
		this.accessibilityStore = new AccessibilityStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	reInitialize() {
		this.appStore.reInitialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			searchStore: this.searchStore,
			searchResultItemStore: this.searchResultItemStore,
			searchActionStore: this.searchActionStore,
			filtersStore: this.filtersStore,
			filterStore: this.filterStore,
			filterButtonsStore: this.filterButtonsStore,
			filterDropdownStore: this.filterDropdownStore,
			filtersActionStore: this.filtersActionStore,
			filtersTitleStore: this.filtersTitleStore,
			allFundsActionStore: this.allFundsActionStore,
			filtersClearActionStore: this.filtersClearActionStore,
			accessibilityStore: this.accessibilityStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}