import { computed } from "mobx";
import { KurtosysApiStoreBase } from "./KurtosysApiStoreBase";
import { GetApplicationAppConfig } from "@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig";
import { ListTranslations } from "@kurtosys/ksys-api-client/dist/requests/config/ListTranslations";
import { IQueryContextClient } from "@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient";
import { ListCommentaries } from "@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries";
import { ListDisclaimers } from "@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers";
import { SearchDocuments } from "@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments";
import { SearchEntity } from "@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity";
import { GetTheme } from "@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme";
import { GetApplicationClientConfiguration } from "@kurtosys/ksys-api-client/dist/requests/applicationManager/GetApplicationClientConfiguration";
import { RetrieveClientConfiguration } from "@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration";
import { SavedSearchEntity } from "@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity";
import { RetrieveDocument } from "@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument";
import { Retrieve } from "@kurtosys/ksys-api-client/dist/requests/documents/Retrieve";
import { RetrieveDocumentZip } from "@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip";
import { GetFilters } from "@kurtosys/ksys-api-client/dist/requests/fund/GetFilters";
import { GetUserByToken } from '@kurtosys/ksys-api-client/dist/requests/auth/getUserByToken';

export class KurtosysApiStore extends KurtosysApiStoreBase {
	@computed
	get client(): IQueryContextClient {
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: this.createEndpoint(RetrieveClientConfiguration),
				},
			},
			commentaries: {
				list: this.createEndpoint(ListCommentaries),
			},
			disclaimers: {
				list: this.createEndpoint(ListDisclaimers),
			},
			documents: {
				search: this.createEndpoint(SearchDocuments),
				retrieve: this.createEndpoint(RetrieveDocument),
				retrieveByProperty: this.createEndpoint(Retrieve),
				retrieveZip: this.createEndpoint(RetrieveDocumentZip),
			},
			entity: {
				search: this.createEndpoint(SearchEntity),
				savedSearchEntity: this.createEndpoint(SavedSearchEntity),
			},
			options: {
				baseUrl: this.serviceUrl,
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return this.createEndpoint(GetApplicationAppConfig);
	}

	@computed
	get listTranslations(): ListTranslations {
		return this.createEndpoint(ListTranslations);
	}

	@computed
	get getTheme(): GetTheme {
		return this.createEndpoint(GetTheme);
	}

	@computed
	get getUserByToken(): GetUserByToken {
		return this.createEndpoint(GetUserByToken);
	}

	@computed
	get getApplicationClientConfiguration(): GetApplicationClientConfiguration {
		return this.createEndpoint(GetApplicationClientConfiguration);
	}

	@computed
	get getFilters(): GetFilters {
		return this.createEndpoint(GetFilters);
	}
}
