import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISearchConfiguration, ISearchResult, IFetchSearchResultsProps, IMapSearchResponseToResultRecordsProps } from '../models';
import { ISearchProps, ISearchPillProps, ISearchResults } from '@kurtosys/ksys-app-components/dist/components/base/Search/models';
import { utils } from '@kurtosys/ksys-app-template';
import { SearchResultItem } from '../../SearchResultItem/SearchResultItem';
import { ISearchResultRecord } from '../../SearchResultItem/models/ISearchResultRecord';
import { ISearchResultOptions } from '../models/ISearchResultOptions';
import { deepMergeObjectsWithOptions } from '@kurtosys/ksys-app-template/dist/utils/object';
import { IEntitiesRequestOptions } from '../../../models/app/IEntitiesRequestOptions';
import { ISearch, IKeyValueString } from '../../../models/commonTypes';
import { isArrayLike } from '@kurtosys/ksys-app-template/dist/utils/isArrayLike';
import { ISearchRequestProps } from '../models/ISearchRequestProps';

export class SearchStore extends StoreBase {
	static componentKey: 'search' = 'search';
	@observable shareClassCount: number | undefined;
	@observable displayPill: boolean = false;
	term: string = '';

	@computed
	get configuration(): ISearchConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SearchStore.componentKey);
		}
	}

	@computed
	get hasData(): boolean {
		const { appStore } = this.storeContext;
		return !!this.configuration;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@action
	async initialize(): Promise<void> {
		this.updateInitialTotalShareClasses();
	}

	getPillProps(resultCount: number): Partial<ISearchPillProps> | undefined {
		const { translationStore } = this.storeContext;
		const { responseCount } = this.searchResultProps;
		if (responseCount) {
			const placeholder = !utils.typeChecks.isNullOrEmpty(responseCount.placeholder) ? responseCount.placeholder : 'count';
			return {
				value: translationStore.translate(responseCount.label, { [placeholder]: resultCount }),
			};
		}
		return;
	}

	appendPillProps(searchResult: any, options: { resultCount: number }) {
		const { resultCount } = options;
		const { pillProps = {} } = searchResult;
		const overridingPillProps = this.getPillProps(resultCount);
		searchResult.pillProps = Object.assign(pillProps, overridingPillProps);
		return searchResult;
	}

	@computed
	get searchResultProps(): ISearchResultOptions {
		let searchResultProps: ISearchResultOptions = {
			searchRequest: {
				searchEntitiesId: 'search',
			},
			noResultText: 'No results found',
		};
		if (this.configuration) {
			const { result } = this.configuration;
			if (result) {
				searchResultProps = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, searchResultProps, result);
			}
			const { configuration: searchResultItemConfiguration } = this.storeContext.searchResultItemStore;
			if (searchResultItemConfiguration && searchResultItemConfiguration.label) {
				Object.assign(searchResultProps, searchResultItemConfiguration);
			}
		}

		return searchResultProps;
	}

	search = async (term: string): Promise<ISearchResults> => {
		const { queryStore, appStore } = this.storeContext;
		this.term = term;
		let searchResults: ISearchResults = {
			term,
			records: [],
		};

		if (this.configuration !== undefined) {
			const { searchRequest, searchParentRequest, noResultText, label: resultItemLabel, parentLabel: resultParentItemLabel } = this.searchResultProps;

			const childResult = await this.fetchSearchResults({
				type: 'child',
				labelLayout: resultItemLabel,
				requestProps: searchRequest,
				inputs: { term },
			});
			if (childResult.total > 0) {
				let totalRecords = childResult.total;
				if (searchParentRequest && searchParentRequest.searchEntitiesId) {

					const childRecordsGroupedByParentClientCode = childResult.records.reduce((groupedRecords, record) => {
						if (record.value.linkedEntity) {
							const entity = record.value.linkedEntity;
							const parentClientCode = entity.properties_pub[searchParentRequest.clientCodeProperty].value;
							if (!groupedRecords[parentClientCode]) {
								groupedRecords[parentClientCode] = [];
							}
							groupedRecords[parentClientCode].push(record);
						}
						return groupedRecords;
					}, {} as { [parentCode: string]: ISearchResultRecord[] });

					const parentClientCodes = Object.keys(childRecordsGroupedByParentClientCode);
					if (parentClientCodes.length > 0) {
						const parentInputs = {
							term,
							[searchParentRequest.clientCodeProperty]: parentClientCodes,
						};

						const parentResult = await this.fetchSearchResults({
							type: 'parent',
							labelLayout: resultParentItemLabel,
							requestProps: searchParentRequest,
							inputs: parentInputs as IKeyValueString,
						});
						if (parentResult.total > 0) {
							const parentRecordsByClientCode = parentResult.records.reduce((groupedRecords: { [key: string]: ISearchResultRecord }, record: ISearchResultRecord) => {
								groupedRecords[record.id] = record;
								return groupedRecords;
							}, {});
							const groupedRecords = parentClientCodes.reduce((records: ISearchResultRecord[], clientCode: string) => {
								records.push(parentRecordsByClientCode[clientCode]);
								records.push(...childRecordsGroupedByParentClientCode[clientCode]);
								return records;
							}, []);
							searchResults.records = groupedRecords;
						}
					}
					else {
						searchResults.records = childResult.records;
					}
				}
				else {
					searchResults.records = childResult.records;
				}
				return this.appendPillProps(searchResults, { resultCount: totalRecords });
			}

			searchResults.records = [
				{
					id: 'noResults',
					value: {
						left: [
							!utils.typeChecks.isNullOrEmpty(noResultText) ? noResultText : 'No Results found',
						],
					},
				},
			];
		}

		return searchResults;
	}

	getEntitiesRequestOptions(requestProps: ISearchRequestProps): IEntitiesRequestOptions | undefined {
		const options: IEntitiesRequestOptions = {
			overrideDynamicInputs: true,
		};
		if (requestProps.observedFilters && requestProps.observedFilters.length > 0) {
			const { filtersStore } = this.storeContext;
			if (filtersStore && filtersStore.selectedFilterValuesCount > 0) {

				const search = requestProps.observedFilters.reduce((criteria, filterId) => {
					if (filtersStore.filterOptionsById[filterId] && filtersStore.selectedFilterValues[filterId]) {
						const filterOption = filtersStore.filterOptionsById[filterId];
						const selectedValues = filtersStore.selectedFilterValues[filterId].reduce((values, filter) => {
							if (filter && !utils.typeChecks.isNullOrEmpty(filter.value) && !isArrayLike(filter.value)) {
								values.push(filter.value as string);
							}
							return values;
						}, [] as string[]);
						if (selectedValues.length > 0) {
							criteria.push({
								property: filterOption.property,
								matchtype: 'MATCH',
								values: selectedValues,
							});
						}
					}
					return criteria;
				}, [] as ISearch[]);

				if (search) {
					options.fetchEntitiesOptions = {
						staticEntityOverrideArrayType: 'replaceValues',
						staticEntityOverride: {
							requestBody: {
								search,
							},
						},
					};
				}
			}
		}
		return options;
	}

	async fetchSearchResults(fetchProps: IFetchSearchResultsProps): Promise<ISearchResult> {
		const { type, requestProps, inputs, labelLayout: resultItemLabel } = fetchProps;
		if (requestProps && requestProps.searchEntitiesId) {
			const { queryStore } = this.storeContext;
			const entitiesRequestOptions = this.getEntitiesRequestOptions(requestProps);
			const response = await queryStore.entitiesRequest(requestProps.searchEntitiesId, inputs, undefined, undefined, entitiesRequestOptions);
			if (response) {
				const records = this.mapSearchResponseToResultRecords({
					type,
					response,
					labelLayout: resultItemLabel,
				});
				if (records) {
					return {
						records,
						total: response.total,
					};
				}
			}
		}
		return {
			records: [],
			total: 0,
		};
	}

	mapSearchResponseToResultRecords(mapProps: IMapSearchResponseToResultRecordsProps): ISearchResultRecord[] | undefined {
		const { appStore } = this.storeContext;
		const { type, response, labelLayout } = mapProps;
		if (response) {
			if (response.total > 0) {
				return response.values.map((entity) => {
					const valueLayout: ISearchResultRecord = {
						type,
						id: entity.clientCode,
						value: {
							linkedEntity: entity,
						},
					};

					if (labelLayout) {
						if (labelLayout.left) {
							valueLayout.value.left = labelLayout.left.map(item => this.getQueryValue(item, undefined, appStore.overrideEntityContext(entity)));
						}

						if (labelLayout.right) {
							valueLayout.value.right = labelLayout.right.map(item => this.getQueryValue(item, undefined, appStore.overrideEntityContext(entity)));
						}
					}

					return valueLayout;
				});
			}
		}
		return;
	}

	onResultItemSelect = (item: ISearchResultRecord) => {
		const { appStore } = this.storeContext;
		if (item && item.id !== 'noResults' && item.value && item.value.linkedEntity) {
			if (item.type === 'parent') {
				appStore.executeRedirectAction('searchResultParentItem', {
					entity: item.value.linkedEntity,
				});
			}
			else {
				appStore.executeRedirectAction('searchResultChildItem', {
					entity: item.value.linkedEntity,
				});
			}
		}
	}

	async updateInitialTotalShareClasses() {
		const { queryStore } = this.storeContext;
		if (this.configuration) {
			const { searchRequest } = this.searchResultProps;
			if (searchRequest.initialSearchCountEntitiesId) {
				let total = 0;
				const searchInputs = { term: '' };
				if (searchRequest.initialSearchCountEntitiesId) {
					const searchResponse = await queryStore.entitiesRequest(searchRequest.initialSearchCountEntitiesId, searchInputs);
					if (searchResponse && searchResponse.total) {
						total += searchResponse.total;
					}
				}
				this.shareClassCount = total;
			}
		}
	}

	@computed
	get props(): ISearchProps {
		let props = this.mergeQueriesAndProps(this.configuration) as ISearchProps;
		if (props) {
			props = Object.assign(props, {
				searchCallback: this.search,
				onResultItemClick: this.onResultItemSelect,
				resultItemComponent: SearchResultItem,
			});

			if (this.shareClassCount !== undefined) {
				props = this.appendPillProps(props, { resultCount: this.shareClassCount });
			}
			else if (props.pillProps) {
				delete props.pillProps;
			}
		}

		return props;
	}
}