import React, { Component } from 'react';
import { IFilterButtonsProps, IFilterButtonsState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ButtonFlatComponent from '@kurtosys/ksys-app-components/dist/components/base/ButtonFlat';
import ButtonRaisedComponent from '@kurtosys/ksys-app-components/dist/components/base/ButtonRaised';
import { IFilterValue } from '../Filter/models';
import { isArrayLike } from '@kurtosys/ksys-app-template/dist/utils/isArrayLike';

@inject('appStore', 'filterButtonsStore')
@observer
export class FilterButtons extends Component<IFilterButtonsProps, IFilterButtonsState> {
	static configurationKey: 'filterButtons' = 'filterButtons';
	static styleKey: 'filterButtons' = 'filterButtons';
	constructor(props: IFilterButtonsProps) {
		super(props);
	}
	onClick = (selectedItem: IFilterValue) => {
		const { onFilterSelect, id, mode, preSelected } = this.props;
		if (onFilterSelect) {
			let selected: IFilterValue[] = preSelected || [];
			if (mode === 'multi') {
				const existingIndex = selected.findIndex(filterValue => filterValue.value === selectedItem.value);
				if (existingIndex >= 0) {
					selected.splice(existingIndex, 1);
				}
				else {
					selected.push(selectedItem);
				}
			}
			else {
				if (selected.length > 0 && selected[0].value === selectedItem.value) {
					selected = [];
				}
				else {
					selected = [selectedItem];
				}
			}
			onFilterSelect(id, selected);
		}
	}
	render() {
		const { id, className, filterButtonsStore, values, preSelected } = this.props;

		if (!filterButtonsStore) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				{ values.map((labelValue, index) => {
					if (preSelected && isArrayLike(preSelected)) {
						if (preSelected.find(selected => selected.value === labelValue.value) !== undefined) {
							return (
								<ButtonFlatComponent key={ `filter-button-${ index }` } text={ labelValue.label } onClick={ () => this.onClick(labelValue) } />
							);
						}
					}
					return (
						<ButtonRaisedComponent key={ `filter-button-${ index }` } text={ labelValue.label } onClick={ () => this.onClick(labelValue) } />
					);
				}) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FilterButtons, 'filterButtons');
