import React, { Component } from 'react';
import { ISearchProps, ISearchState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import SearchComponent from '@kurtosys/ksys-app-components/dist/components/base/Search';
import Wrapper from './styledComponents/Wrapper';

@inject('appStore', 'searchStore')
@observer
export class Search extends Component<ISearchProps, ISearchState> {
	static configurationKey: 'search' = 'search';
	static styleKey: 'search' = 'search';
	constructor(props: ISearchProps) {
		super(props);
	}
	render() {
		const { className, searchStore } = this.props;

		if (!searchStore) {
			return null;
		}

		const { props, show } = searchStore;

		if (!show) {
			return null;
		}

		return (
			<Wrapper className={className}>
				<SearchComponent {...props} />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Search, 'search');
