import React, { Component } from 'react';
import { IFiltersProps, IFiltersState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Filter from '../Filter';

@inject('appStore', 'filtersStore')
@observer
export class Filters extends Component<IFiltersProps, IFiltersState> {
	static configurationKey: 'filters' = 'filters';
	static styleKey: 'filters' = 'filters';
	constructor(props: IFiltersProps) {
		super(props);
	}
	render() {
		const { className, filtersStore } = this.props;

		if (!filtersStore) {
			return null;
		}

		const { show, filters, isExpanded } = filtersStore;

		if (!show || !isExpanded) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				{ filters.map((filterProps, index) => {
					return <Filter key={ `filter-${ index }` } { ...filterProps } />;
				}) }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Filters, 'filters');
