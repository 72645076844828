import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		app: {
			children: {
				wrapper: {
					border: {
						_value: '1px solid #dddddd',
					},
					box: {
						shadow: `0 2px 6px #ccc`,
					},
					padding: {
						_value: '0 25px 25px 25px',
					},
				},
			},
		},
		allFundsAction: {
			children: {
				wrapper: {
					display: 'flex',
					width: '100px',
					height: '35px',
					lineHeight: '50px',
					justifyContent: 'center',
					breakpoints: [
						{
							max: '767px',
							style: {
								height: '45px',
								margin: {
									_value: '5px 0 0 0',
								},
								display: 'block',
								width: 'auto',
								lineHeight: 'initial',
								justifyContent: 'initial',
							},
						},
					],
					overrides: [
						{
							selector: '[data-selector="base-button"]',
							style: {
								cursor: 'pointer',
								color: 'blue',
								background: {
									_value: 'none',
								},
								border: {
									_value: 'none',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											height: '45px',
											color: 'white',
											cursor: 'pointer',
											border: {
												_value: '1px solid #026697',
											},
											background: {
												color: '#1A80FF',
											},
											margin: {
												_value: '0',
											},
											width: '100%',
										},
									},
								],
							},
						},
						{
							selector: '[data-selector="base-button"]:hover',
							style: {
								text: {
									decoration: 'underline',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											background: {
												color: '#2A90FF',
											},
											border: {
												_value: '1px solid #0681bd',
											},
										},
									},
								],
							},
						},
					],
				},
			},
		},
		searchAction: {
			children: {
				wrapper: {
					overrides: [
						{
							selector: 'button[data-selector="base-button"]',
							style: {
								height: '48px',
								color: 'white',
								cursor: 'pointer',
								border: {
									_value: '1px solid #026697',
								},
								background: {
									color: '#1A80FF',
								},
								margin: {
									left: '15px',
								},
								width: '130px',
								breakpoints: [
									{
										max: '767px',
										style: {
											width: '100%',
											margin: {
												_value: '20px 0 0 0',
											},
										},
									},
								],
							},
						},
						{
							selector: 'button[data-selector="base-button"]:hover',
							style: {
								background: {
									color: '#2A90FF',
								},
								border: {
									_value: '1px solid #0681bd',
								},
							},
						},
					],
				},
			},
		},
		searchResultItem: {
			children: {
				wrapper: {
				},
				childResultItem: {
					display: 'flex',
					justifyContent: 'space-between',
					font: {
						size: '14px',
					},
					selectors: {
						':hover': {
							color: '#0075BE',
						},
					},
					padding: {
						_value: '8px 0 8px 25px',
					},
					overrides: [
						{
							selector: '[data-selector="result-item-column-value"]:last-of-type',
							style: {
								color: '#afafaf',
								margin: {
									top: '2px',
								},
							},
						},
					],
				},
				parentResultItem: {
					display: 'flex',
					justifyContent: 'space-between',
					font: {
						size: '16px',
					},
					height: '32px',
					padding: {
						_value: '8px 0 8px 22px',
					},
					selectors: {
						':hover': {
							color: '#00B5FE',
						},
					},
					border: {
						left: {
							_value: `3px solid #00B5FE`,
						},
					},
				},
				leftColumn: {
					display: 'flex',
					justifyContent: 'center',
					align: {
						items: 'flex-start',
					},
					flex: {
						direction: 'column',
					},
				},
				rightColumn: {
					display: 'flex',
					justifyContent: 'center',
					align: {
						items: 'flex-end',
					},
					flex: {
						direction: 'column',
					},
					color: 'grey',
				},
			},
		},
		filtersTitle: {
			children: {
				textWrapper: {
					display: 'inline',
					lineHeight: '35px',
					font: {
						weight: 'normal',
						size: '14px',
					},
					margin: {
						left: '1px',
					},
				},
				iconWrapper: {
					height: '35px',
					lineHeight: '35px',
					display: 'flex',
					justifyContent: 'center',
					flex: {
						direction: 'column',
					},
				},
				wrapper: {
					display: 'flex',
					justifyContent: 'flex-start',
					height: '35px',
					breakpoints: [
						{
							max: '767px',
							style: {
								margin: {
									_value: '10px 0 0 0',
								},
							},
						},
					],
				},
			},
		},
		filtersAction: {
			children: {
				wrapper: {
					height: '66px',
					breakpoints: [
						{
							max: '767px',
							style: {
								height: '45px',
								margin: {
									_value: '5px 0 0 0',
								},
							},
						},
					],
					overrides: [
						{
							selector: 'button[data-selector="base-button"]',
							style: {
								height: '45px',
								color: 'white',
								cursor: 'pointer',
								border: {
									_value: '1px solid #026697',
								},
								background: {
									color: '#1A80FF',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											margin: {
												_value: '0',
											},
											width: '100%',
										},
									},
								],
								margin: {
									top: '20px',
								},
								width: '100px',
							},
						},
						{
							selector: 'button[data-selector="base-button"]:hover',
							style: {
								background: {
									color: '#2A90FF',
								},
								border: {
									_value: '1px solid #0681bd',
								},
							},
						},
					],
				},
			},
		},
		filtersClearAction: {
			children: {
				wrapper: {
					display: 'flex',
					width: '105px',
					height: '35px',
					lineHeight: '50px',
					justifyContent: 'center',
					breakpoints: [
						{
							max: '767px',
							style: {
								height: '45px',
								margin: {
									_value: '5px 0 0 0',
								},
								display: 'block',
								width: 'auto',
								lineHeight: 'initial',
								justifyContent: 'initial',
							},
						},
					],
					overrides: [
						{
							selector: '[data-selector="base-button"]',
							style: {
								cursor: 'pointer',
								color: 'blue',
								background: {
									_value: 'none',
								},
								border: {
									_value: 'none',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											height: '45px',
											color: 'white',
											cursor: 'pointer',
											border: {
												_value: '1px solid #026697',
											},
											background: {
												color: '#1A80FF',
											},
											margin: {
												_value: '0',
											},
											width: '100%',
										},
									},
								],
							},
						},
						{
							selector: '[data-selector="base-button"]:hover',
							style: {
								text: {
									decoration: 'underline',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											background: {
												color: '#2A90FF',
											},
											border: {
												_value: '1px solid #0681bd',
											},
										},
									},
								],
							},
						},
					],
				},
			},
		},
		filter: {
			children: {
				filterTitle: {
					lineHeight: '20px',
					font: {
						size: '14px',
					},
					overrides: [
						{
							selector: '[data-selector="base-text-wrapper"]',
							style: {
								height: 'auto',
								lineHeight: '20px',
							},
						},
					],
				},
			},
		},
		filters: {
			children: {
				wrapper: {
					display: 'flex',
					justifyContent: 'flex-start',
					breakpoints: [
						{
							max: '767px',
							style: {
								flex: {
									direction: 'column',
								},
								margin: {
									bottom: '15px',
								},
							},
						},
					],
				},
			},
		},
		filterButtons: {
			children: {
				wrapper: {
					height: '44px',
					display: 'flex',
					justifyContent: 'flex-start',
					flex: {
						wrap: 'nowrap',
					},
					margin: {
						_value: '0 15px 0 0',
					},
					border: {
						_value: '1px solid #cccccc',
					},
					breakpoints: [
						{
							max: '767px',
							style: {
								justifyContent: 'space-between',
								width: '100%',
								height: 'auto',
								flex: {
									wrap: 'wrap',
								},
							},
						},
					],
					overrides: [
						{
							selector: '[data-selector="base-buttonRaised"],[data-selector="base-buttonFlat"]',
							style: {
								height: '40px',
								color: 'white',
								cursor: 'pointer',
								min: {
									width: '100px',
								},
								background: {
									_value: '#afafaf',
								},
								border: {
									_value: '#afafaf',
								},
								margin: {
									_value: '2px 2px',
								},
								breakpoints: [
									{
										max: '767px',
										style: {
											width: '122px',
										},
									},
								],
							},
						},
						{
							selector: '[data-selector="base-buttonRaised"]:hover',
							style: {
								background: {
									_value: '#bfbfbf',
								},
							},
						},
						{
							selector: '[data-selector="base-buttonFlat"]',
							style: {
								background: {
									_value: '#1A80FF',
								},
							},
						},
						{
							selector: '[data-selector="base-buttonFlat"]:hover',
							style: {
								background: {
									_value: '#3Aa0FF',
								},
							},
						},
					],
				},
			},
		},
		filterDropdown: {
			children: {
				wrapper: {
					margin: {
						_value: '21px 15px 0 0',
					},
					breakpoints: [
						{
							max: '767px',
							style: {
								margin: {
									_value: '10px 0 10px 0',
								},
							},
						},
					],
				},
			},
		},
	},
	theme: {
		base: {
			application: {
				font: {
					family: 'Heebo, Arial',
					weight: 'normal',
				},
				color: '#001928',
			},
			button: {
				outline: {
					_value: 'none',
				},
			},
			heading: {
				children: {
					'h1': {
						font: {
							size: '36px',
							weight: '300',
						},
						lineHeight: '46px',
						margin: {
							top: '0px',
							bottom: '8px',
						},
					},
					'h3': {
						font: {
							size: '14px',
							weight: 'normal',
						},
						lineHeight: '35px',
						margin: {
							_value: '0',
						},
					},
				},
			},
			dropdown: {
				children: {
					wrapper: {
						min: {
							width: '160px',
						},
						breakpoints: [
							{
								max: '767px',
								style: {
									height: '45px',
								},
							},
						],
					},
					header: {
						box: {
							sizing: 'border-box',
						},
						position: 'relative',
						display: 'flex',
						lineHeight: '45px',
						height: '45px',
						cursor: 'pointer',
						color: '#afafaf',
						width: '100%',
						padding: {
							_value: '0 5px',
						},
						font: {
							weight: 'normal',
							size: '16px',
						},
						flex: {
							direction: 'row',
							wrap: 'nowrap',
						},
						justifyContent: 'center',
						border: {
							_value: '1px solid #dfdfdf',
						},
					},
					headerTitle: {
						display: 'inline-block',
					},
					headerIcon: {
						display: 'flex',
						flex: {
							direction: 'column',
						},
						justifyContent: 'center',
					},
					listItems: {
						position: 'absolute',
						background: {
							color: '#FFF',
						},
						listStyle: {
							type: 'none',
						},
						display: 'inline-block',
						width: '160px',
						max: {
							height: '400px',
						},
						overflow: {
							y: 'auto',
						},
						margin: {
							_value: '0',
						},
						padding: {
							_value: '0',
						},
						border: {
							width: '1px',
							style: 'solid',
							color: '#C7C7C7',
						},
						box: {
							shadow: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
						},
						zIndex: '1000',
						breakpoints: [
							{
								max: '767px',
								style: {
									position: 'relative',
									width: '100%',
								},
							},
						],
					},
					listItem: {
						display: 'flex',
						justifyContent: 'space-between',
						color: '#001928',
						font: {
							size: '16px',
							weight: 'normal',
						},
						lineHeight: '24px',
						padding: {
							top: '13px',
							bottom: '13px',
						},
						margin: {
							_value: '0 8px',
						},
						border: {
							bottom: {
								_value: '1px solid #E7E7E7',
							},
						},
						selectors: {
							':hover': {
								color: '#0075BE',
								cursor: 'pointer',
							},
							':first-child': {
								padding: {
									top: '9.5px',
								},
							},
							':last-child': {
								border: {
									_value: 'none',
								},
								padding: {
									bottom: '11.5px',
								},
							},
						},
					},
					listItemLabel: {
						color: '#ababab',
					},
					listItemLabelHighlight: {
						color: '#131313',
					},
					clickLayer: {
						zIndex: '999',
						position: 'absolute',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						background: {
							color: 'transparent',
						},
					},
				},
			},
			pill: {
				display: 'block',
				text: {
					transform: 'uppercase',
				},
				overrides: [
					{
						selector: '[data-selector="base-pill-value"]',
						style: {
							display: 'inline-block',
							padding: {
								_value: '8px 16px',
							},
							font: {
								weight: 'bold',
							},
							conditionals: [
								{
									conditional: {
										conditions: [
											{
												field: 'priority',
												operator: '=',
												value: 'primary',
											},
										],
									},
									style: {
										color: '#ffffff',
									},
								},
							],
						},
					},
				],
			},
			search: {
				children: {
					wrapper: {
						position: 'relative',
						height: '44px',
					},
					searchBar: {
						position: 'absolute',
						zIndex: '3',
						width: '100%',
						display: 'flex',
						background: {
							_value: '#f5f5f5',
						},
						border: {
							_value: '1px solid #dfdfdf',
						},
						padding: {
							_value: '10px 0',
						},
						overflow: {
							_value: 'hidden',
						},
						overrides: [
							{
								selector: 'input[data-selector="base-input"]',
								style: {
									flex: {
										basis: '98%',
									},
									height: '24px',
									background: {
										_value: '#f5f5f5',
									},
									font: {
										size: '16px',
									},
									lineHeight: '20px',
									border: {
										_value: 'none',
									},
									outline: {
										_value: 'none',
									},
									margin: {
										_value: '2px 0 0 0',
									},
									selectors: {
										'::-webkit-input-placeholder': {
											color: '#bbbbbb',
										},
										'::-ms-clear': {
											display: 'none',
										},
									},
								},
							},
						],
					},
					searchClear: {
						min: {
							width: '24px',
						},
						margin: {
							_value: '0 15px',
						},
						cursor: 'pointer',
					},
					searchIcon: {
						flex: {
							basis: '1%',
						},
						margin: {
							_value: '0 15px',
						},
						min: {
							width: '16px',
						},
					},
					searchPill: {
						flex: {
							basis: '1%',
						},
						height: '24px',
						margin: {
							_value: '0 10px',
						},
						padding: {
							_value: '2px 8px 0 8px',
						},
						text: {
							align: 'center',
						},
						width: 'auto',
						whiteSpace: 'nowrap',
						border: {
							radius: '12px',
						},
						conditionals: [
							{
								conditional: {
									conditions: [
										{
											field: 'priority',
											operator: '=',
											value: 'primary',
										},
									],
								},
								style: {
									background: {
										color: '#03a9f4',
									},
								},
							},
							{
								conditional: {
									conditions: [
										{
											field: 'priority',
											operator: '=',
											value: 'secondary',
										},
									],
								},
								style: {
									color: '#777777',
									background: {
										color: '#eeeeee',
									},
								},
							},
							{
								conditional: {
									conditions: [
										{
											field: 'priority',
											operator: '=',
											value: 'tertiary',
										},
									],
								},
								style: {
									color: '#cccccc',
									background: {
										color: 'none',
									},
								},
							},
						],
						overrides: [
							{
								selector: 'span[data-selector="base-pill-value"]',
								style: {
									font: {
										size: '14px',
									},
									lineHeight: '24px',
									padding: {
										_value: '0',
									},
								},
							},
						],
					},
					resultBackground: {
						position: 'fixed',
						top: '0',
						right: '0',
						bottom: '0',
						left: '0',
						background: {
							color: 'rgba(0,0,0,0.0)',
						},
						zIndex: '2',
					},
					resultCard: {
						position: 'absolute',
						top: '47px',
						listStyle: {
							type: 'none',
						},
						padding: {
							_value: '5px 0',
						},
						margin: {
							_value: '0',
						},
						width: '100%',
						background: {
							color: 'white',
						},
						border: {
							_value: '1px solid #ccc',
						},
						box: {
							shadow: `0 2px 4px #ccc`,
						},
						zIndex: '3',
					},
					resultElement: {
						cursor: 'pointer',
						zIndex: '4',
						overrides: [
							{
								selector: '',
								style: {

								},
							},
						],
					},
				},
			},
			icon: {
				children: {
					wrapper: {
						overrides: [
							{
								selector: '[data-selector="base-icon"]',
								style: {
									verticalAlign: 'middle',
								},
							},
						],
					},
				},
			},
			actions: {
				children: {
					wrapper: {
						display: 'flex',
					},
				},
			},
			grid: {
				children: {
					row: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
					},
					column: {
						display: 'flex',
						flex: {
							_value: '1',
							direction: 'column',
						},
					},
				},
			},
			text: {
				children: {
					wrapper: {
						height: '35px',
						lineHeight: '35px',
					},
				},
			},
		},
	},
};