import React, { Component } from 'react';
import { IFilterDropdownProps, IFilterDropdownState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import DropdownComponent from '@kurtosys/ksys-app-components/dist/components/base/Dropdown';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { IDropdownProps } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models';
import { isArrayLike } from '@kurtosys/ksys-app-template/dist/utils/isArrayLike';
import { IFilterValue } from '../Filter/models';

@inject('appStore', 'filterDropdownStore')
@observer
export class FilterDropdown extends Component<IFilterDropdownProps, IFilterDropdownState> {
	static configurationKey: 'filterDropdown' = 'filterDropdown';
	static styleKey: 'filterDropdown' = 'filterDropdown';
	constructor(props: IFilterDropdownProps) {
		super(props);
	}
	onChange = (selectedItem: IDropdownItem | IDropdownItem[]) => {
		const { id, onFilterSelect } = this.props;
		if (onFilterSelect) {
			let selected: IFilterValue[];
			if (selectedItem) {
				if (!isArrayLike(selectedItem)) {
					selected = [selectedItem as IFilterValue];
				}
				else {
					selected = selectedItem as IFilterValue[];
				}
				onFilterSelect(id, selected);
			}
		}
	}
	render() {
		const { className, filterDropdownStore, mode, values, preSelected, placeholder } = this.props;

		if (!filterDropdownStore) {
			return null;
		}

		const props: IDropdownProps = {
			placeholder,
			isMultiSelect: mode === 'multi',
			checkboxPosition: 'right',
			items: values as IDropdownItem[],
			selectedItem: preSelected as IDropdownItem[],
			onChange: this.onChange,
		};

		return (
			<Wrapper className={ className }>
				<DropdownComponent { ...props } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FilterDropdown, 'filterDropdown');
