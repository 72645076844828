import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFiltersConfiguration, IFilterOption, TFilterEntityPropertyValues, IFilterEntityPropertyValues, IKeyValueFilterOption } from '../models';
import { TEntityType, ISearch, IFundGetFiltersResponse, IKeyValueString } from '../../../models/commonTypes';
import { IFilterProps, IFilterValue, TFilterSelectFn } from '../../Filter/models';
import { utils } from '@kurtosys/ksys-app-template';
import { FILTER_INPUT_SEPARATOR } from '../../../constants';

const deepMergeObjectsWithOptions = utils.object.deepMergeObjectsWithOptions;

const FILTER_BUTTON_VALUE_LIMIT = 6;

export class FiltersStore extends StoreBase {
	static componentKey: 'filters' = 'filters';

	@computed
	get configuration(): IFiltersConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FiltersStore.componentKey);
		}
	}

	@computed
	get hasData(): boolean {
		return !!this.filters && this.filters.length > 0;
	}

	@computed
	get show(): boolean {
		return this.hasData;
	}

	@observable
	private areFiltersDisplayed: boolean | undefined;

	@computed
	get isExpanded(): boolean {

		if (this.configuration && this.configuration.expandable === true) {
			if (this.areFiltersDisplayed !== undefined) {
				return this.areFiltersDisplayed;
			}

			if (this.configuration && this.configuration.initiallyExpanded !== undefined) {
				return this.configuration.initiallyExpanded;
			}
		}

		return true;
	}

	set isExpanded(bool: boolean) {
		this.areFiltersDisplayed = bool;
	}

	@action
	toggleExpand = (explicit?: boolean) => {
		if (this.isExpandable) {
			this.isExpanded = explicit !== undefined ? explicit : !this.isExpanded;
		}
	}

	@computed
	get isExpandable(): boolean {
		if (this.configuration) {
			return this.configuration.expandable || false;
		}
		return false;
	}

	@observable
	filterValues: TFilterEntityPropertyValues = {};

	// Defines the emphasised filters to track filter drilling
	@observable
	filterValuesWithData: TFilterEntityPropertyValues = {};

	@observable
	selectedFilterValues: IFilterEntityPropertyValues = {};

	// used to build up the get filter search request as filters are selected and removed
	@observable
	filterSearch: { [key: string]: ISearch } = {};

	@computed
	get selectedFilterValuesCount() {
		const filterIds: string[] = Object.keys(this.selectedFilterValues);
		return filterIds.length;
	}

	@computed
	get fundLists(): string[] {
		const { fundLists } = this.configuration || {};
		return this.getUniqueFundListNames(fundLists);
	}

	@computed
	get excludedFundLists(): string[] {
		const { fundListExclusions } = this.configuration || {};
		return this.getUniqueFundListNames(fundListExclusions);
	}

	private getUniqueFundListNames(list?: string[]): string[] {
		if (Array.isArray(list)) {
			const set: Set<string> = new Set();
			for (const name of list) {
				if (name) {
					// replace any app variables that may have been used in the fund list field
					set.add(utils.replacePlaceholders({}, name, undefined, this.storeContext.appStore.appParamsHelper));
				}
			}
			return Array.from(set);
		}
		return [];
	}

	@computed
	get filterOptions(): IFilterOption[] {
		if (this.configuration && this.configuration.options) {
			return this.configuration.options;
		}
		return [];
	}

	@computed
	get filterOptionsById(): IKeyValueFilterOption {
		return this.filterOptions.reduce((optionsById, option) => {
			optionsById[option.id] = option;
			return optionsById;
		}, {} as IKeyValueFilterOption);
	}

	@computed
	get filters(): IFilterProps[] {
		// use filter options to get sort order based on config definitions
		return this.filterOptions.reduce((filters, option) => {
			const filterProps = this.getFilterProps(option);
			if (filterProps) {
				filters.push(filterProps);
			}
			return filters;
		}, [] as IFilterProps[]);
	}

	@action
	clearFilters() {
		const dup = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, {}, this.filterValues);
		this.selectedFilterValues = {};
		this.filterValues = {};
		this.filterValues = dup;
	}

	@action
	onFilterOptionSelect: TFilterSelectFn = async (id: string, selectedFilters: IFilterValue[]) => {
		const selectedFilterValues: IFilterEntityPropertyValues = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, {}, this.selectedFilterValues);
		const filterOption = this.filterOptionsById[id];
		if (selectedFilters.length === 0) {
			if (selectedFilterValues[id]) {
				delete this.filterSearch[id];
				delete selectedFilterValues[id];
			}
		}
		else if (selectedFilters.length > 0) {
			this.filterSearch[id] = {
				property: filterOption.property,
				matchtype: 'MATCH',
				values: selectedFilters.map(filter => filter.value as string),
			};
			selectedFilterValues[id] = selectedFilters;
		}
		this.filterValuesWithData = await this.fetchFilterValues();
		this.selectedFilterValues = selectedFilterValues;
	}

	filterHasData(id: string, filter: IFilterValue) {
		// if no selected filters then all filters currently have data associated
		if (this.selectedFilterValuesCount !== 0) {
			const filterValuesWithData: IFilterEntityPropertyValues = deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, {}, this.filterValuesWithData);
			const filters = filterValuesWithData[id];
			return filters.some(value => value.value === filter.value);
		}
		return true;
	}

	@computed
	get selectionJoin() {
		if (this.configuration && this.configuration.joinSelectionsWith) {
			return this.configuration.joinSelectionsWith;
		}
		return FILTER_INPUT_SEPARATOR;
	}

	applyCustomSort(option: IFilterOption, collection: IFilterValue[]) {
		const { customSort, sortOptions } = option;
		const { property = 'label', direction = 'ASC' } = sortOptions || {};
		const getSortValue = (item: IFilterValue) => {
			const itemValue = item[property];
			return Array.isArray(itemValue) ? itemValue.join(',') : itemValue;
		};
		if (customSort && customSort.length > 0) {
			const excludedValues = collection.filter(item => !customSort.includes(item.label));
			const sortedExcludedValues = utils.collection.sortBy(excludedValues, getSortValue, direction);
			return [...collection.filter(item => customSort.includes(item.label)), ...sortedExcludedValues];
		}
		return utils.collection.sortBy(collection, getSortValue, direction);
	}

	getFilterProps(option: IFilterOption): IFilterProps | undefined {
		const { entityType, property } = option;
		const entityFilterValues = this.filterValues[entityType];
		const values: IFilterValue[] | undefined = entityFilterValues && entityFilterValues[property];
		const maxButtonValuesAllowed = option.maximumButtonValuesAllowed || FILTER_BUTTON_VALUE_LIMIT;
		if (values) {
			const entityFilterValuesWithData = this.filterValuesWithData[entityType];
			const valuesWithData: IFilterValue[] | undefined = entityFilterValuesWithData && entityFilterValuesWithData[property];
			const mappedValues = values.map((value: IFilterValue) => {
				let highlight: boolean = this.selectedFilterValuesCount === 0;
				if (!highlight) {
					highlight = (valuesWithData && valuesWithData.some(valueWithData => valueWithData.value === value.value)) || false;
				}
				return {
					...value,
					highlight,
				};
			});
			return {
				values: this.applyCustomSort(option, mappedValues),
				id: option.id,
				title: option.title && this.storeContext.translationStore.translate(option.title),
				type: mappedValues.length > maxButtonValuesAllowed ? 'dropdown' : option.type || 'dropdown',
				mode: option.mode || 'single',
				placeholder: option.placeholder || 'Select an item',
				onFilterOptionSelect: this.onFilterOptionSelect,
				preSelected: this.getSelectedFilterPropertyValues(option.id),
			};
		}
		return;
	}

	getSelectedFilterPropertyValues(id: string): IFilterValue[] | undefined {
		return this.selectedFilterValues[id];
	}

	@action
	async initialize(): Promise<void> {
		this.filterValues = await this.fetchFilterValues();
	}

	@action
	async fetchFilterValues() {
		const entitiesToUse: TEntityType[] = this.filterOptions.reduce((entities: TEntityType[], option) => {
			if (entities.indexOf(option.entityType) < 0) {
				entities.push(option.entityType);
			}
			return entities;
		}, []);

		const values = await entitiesToUse.reduce(async (acc: Promise<TFilterEntityPropertyValues>, entityType: TEntityType) => {
			const filterValues = await Promise.resolve(acc);
			filterValues[entityType] = await this.fetchFilterValuesByType(entityType) || {};
			return Promise.resolve(filterValues);
		}, Promise.resolve({}));
		return values;
	}

	async fetchFilterValuesByType(entityType: TEntityType): Promise<IFundGetFiltersResponse | undefined> {
		let response;
		const options = this.filterOptions.filter(option => option.entityType === entityType);
		if (options.length > 0) {
			const search = [];
			if (this.filterSearch) {
				for (const key of Object.keys(this.filterSearch)) {
					search.push(this.filterSearch[key]);
				}
			}

			response = await this.storeContext.kurtosysApiStore.getFilters.execute({
				body: {
					filterProperties: options.map(option => option.property),
					type: entityType,
					fundList: this.fundLists.length > 0 ? this.fundLists : undefined,
					fundListExclusion: this.excludedFundLists.length > 0 ? this.excludedFundLists : undefined,
					// the initial search is used as the primary data, the follow up search is used to check if data exists
					// for the selected filters. Therefore, we should not have to worry about the allowed values.
					search: search.length > 0 ? search : options.reduce((search, option) => {
						if (option.allowedValues && option.allowedValues.length > 0) {
							search.push({
								property: option.property,
								matchtype: 'MATCH',
								values: option.allowedValues as string[],
							});
						}
						return search;
					}, [] as ISearch[]),
				},
			});
			if (response) {
				for (const option of options) {
					const allowedValues = option.allowedValues;
					// Further filters the results to show only allowedValues
					if (allowedValues && allowedValues.length > 0) {
						response[option.property] = response[option.property].filter(item => (allowedValues).map(String).includes(item.value as string));
					}
					if (option.valueAliases && response[option.property]) {
						const basePropertyValues = response[option.property];
						for (let i = 0; i < basePropertyValues.length; i++) {
							const labelValue = basePropertyValues[i];
							if (option.valueAliases[labelValue.label]) {
								response[option.property][i].label = option.valueAliases[labelValue.label];
							}
						}
					}
				}
			}
		}
		return response;
	}
}