import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFilterButtonsConfiguration } from '../models';

export class FilterButtonsStore extends StoreBase {
	static componentKey: 'filterButtons' = 'filterButtons';

	@computed
	get configuration(): IFilterButtonsConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FilterButtonsStore.componentKey);
		}
	}

	@action
	async initialize() : Promise<void> {

	}
}