import { models } from '@kurtosys/ksys-app-template';
import { IAppConfigurationBase } from '../models/app/IAppConfigurationBase';

export const APPLICATION_CLIENT_CONFIGURATIONS: models.api.applicationManager.IApplicationClientConfiguration[] = [
	{
		applicationClientConfigurationId: 1,
		configuration: {
			culture: {
				base: 'Default',
				default: 'en-GB',
			},
			core: {
				serviceUrl: 'https://api-rel.fundpress.io',
				skeletonLoaders: {
					variables: [
						{
							key: 'fillColor',
							value: '#dddddd',
						},
					],
					loaders: [
						{
							id: 'ksys-app-start',
							breakpoints: [
								{
									minWidthInPixels: 0,
									style: {
										height: '86px',
									},
									children: [
										{
											'fill': '{fillColor}',
											'x': '0px',
											'y': '11.90625px',
											'height': '28px',
											'width': '100%',
										},
										{
											'fill': '{fillColor}',
											'x': '0px',
											'y': '59.8125px',
											'height': '18px',
											'width': '100%',
										},
									],
								},
							],
						},
					],
				},
			},
			data: {
				context: {
					entities: [
						{
							id: 'classSearch',
							inputs: [],
							requestBody: {
								includeProperties: ['fund_code', 'fund_name', 'isin', 'short_name'],
								identifierSearch: {
									fuzzy: false,
									properties: [
										'fund_code',
										'fund_name',
										'short_name',
										'isin',
									],
									value: '{input:term}',
								},
								include: {},
								limit: 10,
								preserveOriginal: true,
								search: [],
								sort: [
									{
										direction: 'ASC',
										key: 'fund_name',
									},
									{
										direction: 'ASC',
										key: 'share_class_name',
									},
									{
										direction: 'ASC',
										key: 'isin',
									},
								],
								type: 'CLSS',
							},
						},
						{
							id: 'initSearchClassCount',
							inputs: [],
							requestBody: {
								includeProperties: ['isin'],
								identifierSearch: {
									fuzzy: false,
									properties: [
										'fund_code',
										'fund_name',
										'short_name',
										'isin',
									],
									value: '{input:term}',
								},
								search: [],
								limit: 1,
								preserveOriginal: true,
								type: 'CLSS',
							},
						},
						{
							id: 'fundSearch',
							requestBodyQueries: {
								search: [
									{
										property: {
											queryOptionsType: 'none',
											value: 'fund_code',
										},
										matchtype: 'MATCH',
										values: {
											queryOptionsType: 'input',
											key: 'fund_code',
										},
									},
								],
							},
							requestBody: {
								includeProperties: ['fund_code', 'fund_name'],
								include: {},
								preserveOriginal: true,
								search: [],
								sort: [
									{
										direction: 'ASC',
										key: 'fund_name',
									},
								],
								type: 'FUND',
							},
						},
					],
				},
			},
			accessibilityConfig: {
				asset: {
					base: {
						accordionPanel: {
							collapseIcon: {
								value: 'Button to close the panel',
							},
							expandIcon: {
								value: 'Button to open the panel',
							},
						},
					},
				},
				tables: {
					performance: {
						caption: {
							value: 'Past Performance',
						},
					},
				},
			},
		} as IAppConfigurationBase,
		name: 'root',
		applicationClientConfigurationTypeId: 1,
	},
];
