import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFilterDropdownConfiguration } from '../models';

export class FilterDropdownStore extends StoreBase {
	static componentKey: 'filterDropdown' = 'filterDropdown';

	@computed
	get configuration(): IFilterDropdownConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FilterDropdownStore.componentKey);
		}
	}

	@action
	async initialize() : Promise<void> {

	}
}