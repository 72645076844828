import React, { Component } from 'react';
import { ISearchResultItemProps, ISearchResultItemState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import LeftColumn from './styledComponents/LeftColumn';
import RightColumn from './styledComponents/RightColumn';
import ColumnItem from './styledComponents/ColumnItem';
import ChildResultItem from './styledComponents/ChildResultItem';
import ParentResultItem from './styledComponents/ParentResultItem';

@inject('appStore', 'searchResultItemStore')
@observer
export class SearchResultItem extends Component<ISearchResultItemProps, ISearchResultItemState> {
	static configurationKey: 'searchResultItem' = 'searchResultItem';
	static styleKey: 'searchResultItem' = 'searchResultItem';
	constructor(props: ISearchResultItemProps) {
		super(props);
	}

	getColumnItems(columnItems: string[]) {
		return columnItems.map((item, key) => <ColumnItem key={ key } data-selector={ `result-item-column-value` }>{ item }</ColumnItem>);
	}

	render() {
		const { className, searchResultItemStore, record } = this.props;

		if (!searchResultItemStore) {
			return null;
		}

		const ResultItem = record.type === 'parent' ? ParentResultItem : ChildResultItem;

		return (
			<Wrapper data-selector={ `result-item-wrapper` } className={ className }>
				<ResultItem data-selector={ `result-item-${ record.type }` }>
					{ record.value.left && <LeftColumn data-selector={ `result-item-column-left` }>{ this.getColumnItems(record.value.left) }</LeftColumn> }
					{ record.value.right && <RightColumn data-selector={ `result-item-column-right` }>{ this.getColumnItems(record.value.right) }</RightColumn> }
				</ResultItem>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SearchResultItem, 'searchResultItem');
